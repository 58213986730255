<template>
  <div>
    <v-card
      v-if="!procesing"
      class="mx-auto mt-5 d-flex align-center flex-column"
    >
      <v-card-title class="pb-b"><h4>Upload a file</h4></v-card-title>
      <v-divider></v-divider>
      <v-card-text class="d-flex justify-center align-center flex-column">
        <v-form
          class="d-flex align-center justify-center flex-column"
          enctype="multipart/form-data"
          @submit.prevent="processUpload"
        >
          <v-file-input
            ref="audio"
            show-size
            truncate-length="15"
            accept=".wav,.mp3"
            placeholder="Choose a file..."
            outlined
            dense
            :prepend-icon="icons.mdiWaveform"
            @change="selectFile"
          ></v-file-input>

          <v-radio-group row v-model="upload_lang">
            <v-radio label="English" name="lang" value="en"></v-radio>
            <v-radio label="Portuguese" name="lang" value="pt"></v-radio>
          </v-radio-group>

          <v-btn
            color="primary"
            :disabled="!file"
            @click="processUpload"
            rounded
          >
            <v-icon left>fa-keyboard</v-icon>
            Process
          </v-btn>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <router-link :to="{ name: 'record' }" class="card-footer-item"
          >Or make a recording</router-link
        >
      </v-card-actions>
    </v-card>
    <Processing
      v-if="procesing"
      title="Let me transcribe this"
      message="Please, be patient. It won't take long."
    />
  </div>
</template>
<script>
import { mdiWaveform } from "@mdi/js"
import { mapState } from "vuex"
import Processing from "@/components/Processing.vue"

export default {
  name: "Upload",
  components: { Processing },
  data() {
    return {
      icons: { mdiWaveform },
    }
  },
  computed: {
    upload_lang: {
      get() {
        return this.$store.state.speechgraph.lang
      },
      set(value) {
        this.$store.commit("speechgraph/changeLang", value)
      },
    },
    ...mapState({
      procesing: state => state.speechgraph.procesing,
      file: state => state.speechgraph.audio,
    }),
  },
  methods: {
    selectFile(selectedFile) {
      const file = selectedFile
      this.$store.commit("speechgraph/audio", file)

      console.log(this.file)
    },
    processUpload() {
      this.$store.dispatch("speechgraph/processUpload")
    },
  },
}
</script>
